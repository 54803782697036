.checkout-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .head {
    padding: 20px 0;
    border-bottom: 1px solid #dedede;
    .head-content {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-weight: 600;
        font-size: 34px;
        font-family: serif;
      }
      .icon {
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
  }
  .check-body {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    .left {
      width: 55%;
      display: flex;
      justify-content: end;
      height: 100%;
      .form-box {
        width: 660px;
        padding: 38px;
        .title {
          font-weight: 500;
          font-size: 22px;
          margin: 0 0 15px;
        }
        input {
          height: 48px;
          width: 100%;
          padding: 10px;
          border: 1px solid #dedede;
          border-radius: 5px;
          margin: 0 0 25px;
          &:focus {
            outline: none;
            border: 2px solid #3d812f;
          }
        }
        .ReactFlagsSelect-module_selectBtn__19wW7 {
          margin: 0 0 20px;
          height: 48px;
          &:focus {
            outline: none;
            border: 2px solid #3d812f;
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .half {
            width: 49%;
            input {
              height: 48px;
              width: 100%;
              padding: 10px;
              border: 1px solid #dedede;
              border-radius: 5px;
              margin: 0 0 25px;
              &:focus {
                outline: none;
                border: 2px solid #3d812f;
              }
            }
          }
        }
        .react-tel-input {
          width: 100%;
          height: 48px;
          .flag-dropdown {
            border: 0;
          }

          input {
            width: 100%;
            height: 48px;
            border: 1px solid #dedede;
            font-size: 14px;
            overflow: hidden;
            box-shadow: none !important;
            padding-left: 60px;
            &::placeholder {
              font-size: 14px;
            }
            &:focus {
              outline: none;
              border: 2px solid #3d812f;
            }
          }
          .selected-flag {
            border: 0;
            background-color: #fff;
            left: 11px;
            transform: scale(1.5, 1.5);
            top: 8px;
          }
        }
        .tip{
            margin: 30px 0;
            font-size: 15px;
            font-weight: 500;
            color: #df1111;
        }
        .btn {
          margin: 30px 0 0;
          padding: 10px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #3d812f;
          color: #fff;
          font-weight: 500;
          border-radius: 5px;
          transition: all 0.3s;
          opacity: 1;
          &:hover {
            background-color: #2f6424;
            cursor: pointer;
          }
        }
        .disable {
          opacity: .6;
          &:hover{
            cursor: not-allowed;
          }
        }
      }
    }
    .right {
      width: 45%;
      border-left: 1px solid #dedede;
      background-color: #fafafa;
      display: flex;
      .info-box {
        width: 520px;
        padding: 38px;
        .tab-li {
          height: 64px;
          width: 100%;
          margin: 0 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .l {
            display: flex;
            align-items: center;
            .img-box {
              position: relative;
              width: 64px;
              height: 64px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
              }
              .num {
                position: absolute;
                top: -8px;
                right: -8px;
                width: 22px;
                height: 22px;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 500;
                font-size: 13px;
              }
            }
            .name {
              margin-left: 20px;
              width: 300px;
              font-size: 14px;
            }
          }
          .total {
            font-size: 15px;
            // font-weight: 700;
          }
        }
        .subtotal {
          padding: 20px 0 0;
          border-top: 1px dashed #dedede;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          .price {
            font-weight: 500;
          }
        }
        .shipping {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
        }
        .total-row {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          .price {
            font-size: 21px;
          }
        }
      }
    }
  }
}
