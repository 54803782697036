.contact-page {
  width: 800px;
  margin: 0 auto 50px;
  padding: 50px 0;
  h2 {
    font-weight: 500;
    font-size: 28px;
  }
  p {
    margin: 20px 0;
    font-size: 18px;
  }
  .row {
    margin: 20px 0 30px;
    p {
      margin: auto;
      font-size: 15px;
    }
    input {
      margin: 5px 0 0;
      width: 100%;
      padding: 7px 15px;
      background-color: #f5f5f5;
      border: 1px solid #e5e5e5;
      &:focus {
        outline: none;
        background-color: #fff;
      }
      &:hover {
        background-color: #fff;
      }
    }
    textarea {
      margin: 5px 0 0;
      width: 100%;
      min-height: 200px;
      padding: 7px 15px;
      background-color: #f5f5f5;
      border: 1px solid #e5e5e5;
      &:focus {
        outline: none;
        background-color: #fff;
      }
      &:hover {
        background-color: #fff;
      }
    }
    .btn {
      width: 120px;
      padding: 10px 20px;
      background-color: #1f4b16;
      color: #fff;
      display: flex;
      justify-content: center;
    }
  }
}
