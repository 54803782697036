.number-box {
  width: 120px;
  height: 40px;
  border: 1px solid #e5e5e5;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .num {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
