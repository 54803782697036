.nav {
  height: 35px;
  width: 100%;
  background-color: #1f4b16;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.second-nav {
  width: 1200px;
  margin: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: 600;
    font-size: 34px;
    font-family: serif;
  }
  p {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    padding: 0 5px;
  }
}

.head-bar {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  li {
    font-size: 18px;
    font-weight: 500;
    padding: 0 20px;
    &:hover {
      cursor: pointer;
      color: #31911d;
    }
  }
}
