.banner {
  width: 100%;
  height: 570px;
  background: url(../image/banner.webp) no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-weight: 500;
    font-size: 30px;
  }
  .btn {
    margin: 20px 0 0;
    padding: 10px 20px;
    background-color: #1f4b16;
    color: #fff;
    border: 1px solid #fff;
  }
}

.sec1-row {
  width: 1200px;
  margin: 30px auto;
  .title {
    font-size: 24px;
    font-weight: 500;
    text-decoration: underline;
    text-align: center;
    margin: 20px 0;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .swiper{
      width: 100%;
    }
    .col {
      .img-box {
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        margin: 10px 0 0;
        font-weight: 500;
      }
      .price {
        margin: 10px 0 0;
        font-size: 20px;
      }
      .btn {
        margin: 20px 0;
        padding: 10px 20px;
        width: 100%;
        text-align: center;
        border: 1px solid #1f4b16;
        color: #1f4b16;
        transition: all 0.3s;
        &:hover {
          background-color: #1f4b16;
          color: #fff;
        }
      }
    }
  }
}

.sec2 {
  margin: 30px 0;
  width: 100%;
  padding: 30px 0;
  background-color: #1f4b16;
  .sec2-row {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .li {
      width: 25%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .icon {
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        color: #fff;
      }
    }
  }
}

.banner2 {
  height: 570px;
  width: 100%;
  background: url(../image/banner2.webp) no-repeat;
  background-size: cover;
  position: relative;
  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fafafa;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: 500;
    }
    p {
      width: 540px;
      margin: 10px 0 0;
      line-height: 25px;
      font-size: 18px;
    }
  }
}

.banner3 {
  height: 570px;
  width: 100%;
  background: url(../image/banner3.jpg) no-repeat;
  background-size: cover;
}
