.productDetail-page {
  width: 1200px;
  margin: auto;

  .product-row {
    display: flex;
    padding: 50px 0;
    .left {
      padding: 10px;
      width: 50%;
      .swiper {
        width: 500px;
        height: 500px;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .swiper-row {
        margin: 10px 0 0;
        .swiper {
          height: 80px;
          .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .right {
      padding: 10px;
      .title {
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 20px;
        line-height: 25px;
      }
      .price {
        font-size: 24px;
        font-weight: 500;
        padding: 20px 0;
        border-bottom: 1px dotted #e5e5e5;
      }
      .free-ship {
        margin: 20px 0 0;
        background-color: #3d812f;
        color: #fff;
        font-size: 14px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        .ship-icon {
          margin-right: 10px;
        }
      }
      .number-row {
        margin: 20px 0 0;
        display: flex;
        align-items: center;
        .addToCart {
          margin-left: 20px;
          width: 100%;
          height: 40px;
          background-color: #1f4b16;
          color: #fff;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          animation: move 5s ease 2s infinite;
        }
      }
      .info {
        margin: 20px 0 0;
        border-top: 1px solid #e5e5e5;
        .title {
          padding: 10px 0;
          font-size: 18px;
          font-weight: normal;
          margin: auto;
        }
        .content {
          line-height: 25px;
          font-size: 15px;
        }
      }
    }
  }
  //   related
  .related-row {
    margin: 50px 0;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      margin: 0 0 20px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .swiper{
        width: 100%;
      }
      .col {
        .img-box {
          width: 250px;
          height: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          margin: 10px 0 0;
          line-height: 20px;
          font-weight: 500;
        }
        .price {
          margin: 10px 0 0;
          font-size: 20px;
        }
        .btn {
          margin: 20px 0;
          padding: 10px 20px;
          width: 100%;
          text-align: center;
          border: 1px solid #1f4b16;
          color: #1f4b16;
          transition: all 0.3s;
          &:hover {
            background-color: #1f4b16;
            color: #fff;
          }
        }
      }
    }
  }
}

@keyframes move {
  3% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }
  6% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  8% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  10% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  13% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  16% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
