.service-page {
    width: 800px;
    margin: 0 auto 50px;
    h1 {
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      margin: 30px 0;
    }
    p {
      line-height: 25px;
      font-weight: 400;
      margin: 20px 0;
      span {
        color: #31911d;
      }
    }
  }
  