.footer-row {
  width: 100%;
  border-top: 1px solid rgb(229, 229, 229);
  .footer {
    width: 1200px;
    margin: auto;
    padding: 50px 0;
    .row1 {
      display: flex;
      align-items: center;
      .left {
        width: 50%;
        h1 {
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 10px;
        }
        p {
          line-height: 20px;
          font-size: 14px;
        }
      }
      .right {
        width: 50%;
        display: flex;
        justify-content: space-between;
        input {
          width: 70%;
          padding: 10px 15px;
          border: 1px solid rgb(229, 229, 229);
          background-color: rgb(245, 245, 245);
        }
        .btn {
          width: 25%;
          text-align: center;
          padding: 10px 15px;
          border: 1px solid #000;
          transition: all 0.3s;
          &:hover {
            background-color: #1f4b16;
            color: #fff;
          }
        }
      }
    }
    .row2 {
      display: flex;
      // justify-content: space-between;
      margin: 50px 0 0;
      .col {
        width: 30%;
        h1 {
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 10px;
        }
        p {
          margin: 0 0 10px;
          font-size: 15px;
        }
      }
    }
  }
}
