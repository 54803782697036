.ship-page {
  width: 800px;
  margin: 0 auto 50px;
  h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }
  p {
    line-height: 25px;
    font-weight: 500;
    margin: 20px 0;
    span {
      color: #31911d;
    }
  }
  .table {
    margin: 20px 0;
    .li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #000;
      .l {
        width: 33.3%;
        height: 60px;
        padding: 5px 10px;
        border-left: 1px solid #000;
        h1 {
          font-size: 15px;
          text-align: left;
          margin: auto;
        }
        p {
          margin: auto;
        }
        &:nth-child(3) {
          border-right: 1px solid #000;
        }
      }
      &:nth-child(6) {
        border-bottom: 1px solid #000;
      }
    }
  }
}
