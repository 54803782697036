.cart-page {
  width: 1200px;
  margin: 0 auto 50px;
  h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }
  .product-body {
    display: flex;
    justify-content: space-between;
    .left {
      width: 75%;
      .th {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid #bbbbbb;
        padding: 10px;
        .product {
          width: 60%;
        }
        .price {
          width: 10%;
        }
        .quantity {
          width: 20%;
        }
        .total {
          width: 10%;
        }
      }
      .tb {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 10px;
        border-top: 1px solid #bbbbbb;
        .product {
          display: flex;
          align-items: center;
          width: 60%;
          img {
            margin: 0 20px;
            width: 100px;
            height: 100px;
          }
          p {
            margin: 0 20px;
            max-width: 200px;
            word-wrap: break-word;
            font-weight: 500;
          }
        }
        .price {
          width: 10%;
        }
        .quantity {
          width: 20%;
        }
        .total {
          width: 10%;
        }
      }
    }
    .right {
      width: 22%;
      height: 300px;
      margin: 0 15px;
      border: 1px solid #e5e5e5;
      background-color: #f5f5f5;
      padding: 20px;
      .title {
        font-size: 23px;
        font-weight: 500;
        padding: 0 0 20px;
        border-bottom: 1px dashed #bbbbbb;
      }
      .subtotal {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        flex-wrap: wrap;
        span {
          display: block;
          font-size: 25px;
          font-weight: 500;
        }
      }
      .btn {
        margin: 20px 0;
        padding: 10px 20px;
        background-color: #1f4b16;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        transition: all 0.3s;
        border: 1px solid #1f4b16;
        &:hover {
          background-color: #fff;
          color: #1f4b16;
        }
      }
    }
  }
  .no-product {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      margin: 0 0 30px;
    }
    p {
      margin: 0 0 30px;
    }
    .btn {
      padding: 10px 20px;
      border: 1px solid #1f4b16;
      color: #1f4b16;
      transition: all 0.3s;
      &:hover {
        background: #1f4b16;
        color: #fff;
      }
    }
  }
}
